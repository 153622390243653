import React, { useState } from 'react'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import Content from 'components/new/content'
import ProductCategoryGrid from 'components/new/product-category-grid'
import BreadcrumbsScript from 'components/scripts/breadcrumbs'

const ManitouCategory = props => {
  const {
    data: { allSanityManitouEquipmentSubcategory, sanityManitouEquipmentCategory },
  } = props

  const [{ breadcrumbs, meta, subcategoryData }] = useState(() => {
    const breadcrumbs = [
      {
        name: 'Home',
        schemaName: 'Hutson Inc',
        link: '/',
      },
      {
        name: 'Manitou',
        link: '/manitou/',
      },
      {
        name: sanityManitouEquipmentCategory.title,
        link: `/manitou/${sanityManitouEquipmentCategory.handle}/`,
      },
    ]

    const meta = {
      title: `Manitou ${sanityManitouEquipmentCategory.title} | Hutson Inc`,
      description: `Check out Manitou ${sanityManitouEquipmentCategory.title} at Hutson.`,
      keywords: [sanityManitouEquipmentCategory.title].toString(),
    }

    const subcategoryData = allSanityManitouEquipmentSubcategory.nodes.map(node => {
      return {
        link: `/manitou/${sanityManitouEquipmentCategory.handle}/${node.handle}/`,
        image: node.thumbnail.asset.gatsbyImageData,
        title: node.title,
        imageAlt: `Manitou ${node.title}`,
      }
    })

    return { breadcrumbs, meta, subcategoryData }
  })
  return (
    <Layout>
      <Helmet>
        <title>{meta.title}</title>
        <meta name='description' content={meta.description} />
        <meta name='keywords' content={meta.keywords} />
      </Helmet>

      <BreadcrumbsScript breadcrumbs={breadcrumbs} />

      <Hero
        title={`Manitou ${sanityManitouEquipmentCategory.title}`}
        image={sanityManitouEquipmentCategory.heroImageDesktop.asset.gatsbyImageData}
        breadcrumbs={breadcrumbs}
        overlayOpacity={0.15}
      />

      <Content kind='full'>
        <ProductCategoryGrid categories={subcategoryData} />
      </Content>
    </Layout>
  )
}

const Hero = styled(HeroImageTitle)`
  height: 400px;
`

export const pageQuery = graphql`
  query manitouCategoryTemplate($category: String!) {
    sanityManitouEquipmentCategory(handle: { eq: $category }) {
      handle
      heroImageDesktop {
        asset {
          gatsbyImageData(width: 1366)
        }
      }
      title
    }
    allSanityManitouEquipmentSubcategory(
      filter: { parentCategory: { handle: { eq: $category } } }
    ) {
      nodes {
        handle
        thumbnail {
          asset {
            gatsbyImageData(width: 240)
          }
        }
        title
      }
    }
  }
`

export default ManitouCategory
